/* import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function StepForm() {
  const [step, setStep] = useState(1);
  const [details, setDetails] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const handleNext = (data) => {
    setDetails({ ...details, ...data });
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
    setStep(step + 1);
  };

  return (
    <div>
      {step === 1 && <Step1 onNext={handleNext} formData={details} />}
      {step === 2 && <Step2 onNext={handleImageSelect} onBack={handleBack} formData={details} />}
      {step === 3 && <Step3 details={details} selectedImage={selectedImage} onBack={handleBack} formData={details} />}
    </div>
  );
}

export default StepForm; */
import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

function MyForm() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const handleNext = (data) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  
  const handleImageSelect = (image , size) => {
    setSelectedImage(image);
    setSelectedSize(size);
    setStep(step + 1);
  };

  return (
    <div>
      {step === 1 && <Step1 onNext={handleNext} formData={formData} />}
      {step === 2 && (
        <Step2 onNext={handleImageSelect} formData={formData} onPrevious={handlePrevious} />
      )}
      {step === 3 && (
        <Step3 onNext={handleNext} formData={formData} onPrevious={handlePrevious} selectedImage={selectedImage} selectedSize={selectedSize} />
      )}
	  {step === 4 && <Step4 formData={formData} onPrevious={handlePrevious} selectedImage={selectedImage} selectedSize={selectedSize} />}
    </div>
  );
}

export default MyForm;