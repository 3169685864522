import React from 'react';
import logo from '../images/ci-logo-footer-1.png';

const Footer = () => {
    return (
        <footer>
            <div class="footer">
                <div class="container">
                    <div class="footer-top">
                        <div class="row">
                            <div class="col-lg-5 col-md-12 col-sm-12">
                                <div class="footer-img">
                                    <a class="navbar-brand" href="#"><img src={logo} alt="" /></a>
                                    <div class="footer-content">
                                        <p>Subscribe to our newsleter, <br/>
                                            Enter your emil address</p>
                                        <div class="search-container">
                                            <form action="#">
                                                <input class="search-bar" type="text" placeholder="email@example.com"
                                                    name="search" />
                                                <button type="submit">Subscribe</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 col-sm-12">
                                <div class="links">
                                    <span>Links</span>
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">About</a></li>
                                        <li><a href="#">books</a></li>
                                        <li><a href="#">Chapter</a></li>
                                        <li><a href="#">Pricing</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 col-sm-12">
                                <div class="links">
                                    <span>Quik Links</span>
                                    <ul>
                                        <li><a href="#">Privercy Policy</a></li>
                                        <li><a href="#">Terms Of Use</a></li>
                                        <li><a href="#">Copy Right</a></li>
                                        <li><a href="#">Help</a></li>
                                        <li><a href="#">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <div class="info">
                                    <span>Contact</span>
                                    <div class="address">
                                        <ul>
                                            <li><span>Address:</span></li>
                                            <li>
                                                <p>3931 Pine Garden Lane, Arizona, <br/>
                                                Sedona, United States 86341
                                                </p>
                                            </li>
                                            <li><span>Email:</span></li>
                                            <li><a class="email-hover" href="#">email@example.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-bottum">
                        <p>© 2023 Bright. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer >
    );
};

export default Footer;


