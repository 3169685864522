import React from 'react';
import QRCode from 'qrcode.react';

function CardNo4({ selectedImage, formData, selectedSize, qr}) {

	const generateVCard = () => {
		const { name, profession, email, phone, location} = formData;
			return `BEGIN:VCARD
			VERSION:3.0
			FN:${name}
			TEL:${phone}
			EMAIL:${email}
			END:VCARD`;
	};
	  
	const qrData = generateVCard();
	
    return (
		<div class="Portrait-bg ci-card-bg-for portrait-card-size" style={{ backgroundImage: `url(${selectedImage})` }}>
			<div class="portrait-card">
				<div class="container portrait-center">
					<div class="portrait-card-content black-txt">
						<div class="name">
							<h6>{formData.name}</h6>
						</div>
						<div class="profession fi-size">{formData.profession}</div>
						<div class="vertical-qr"><QRCode value={qrData} size={80}/></div>
					</div>
					<div class="ci-portrait-card-content">
						<div class="d-flex contact">
							<div class="fi-size"><i
									class="fa-solid fa-envelope icon"></i>{formData.email}</div>
						</div>
						<div class="d-flex contact">
							<div class="fi-size"><i
									class="fa-solid fa-phone icon"></i>{formData.phone}</div>
						</div>
						<div class="d-flex contact">
							<div class="fi-size"><i
									class="fa-solid fa-location-dot icon"></i>{formData.location}
							</div>
						</div>
						<div class="d-flex contact">
							<div class="fi-size"><i
									class="fa-solid fa-globe icon"></i>{formData.website}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
};

export default CardNo4;


