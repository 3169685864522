import React from 'react';
import QRCode from 'qrcode.react';

function CardNo1({ selectedImage, formData, selectedSize, qr}) {

	const generateVCard = () => {
		const { name, profession, email, phone, location} = formData;
			return `BEGIN:VCARD
			VERSION:3.0
			FN:${name}
			TEL:${phone}
			EMAIL:${email}
			END:VCARD`;
	};
	  
	const qrData = generateVCard();
	
    return (
		<div className={`landscape-bg horizontal-card customone ${selectedSize}`} style={{ backgroundImage: `url(${selectedImage})` }}>
				<div class="landscape-card-content">
					<div class="container content-center">
						<div class="row">
							<div class="col-lg-4 col-md-4 col-sm-4 col-4">
							<div class="qrone"><QRCode value={qrData} size={80}/></div>
							</div>
							<div class="col-lg-8 col-md-8 col-sm-8 col-8">
							<div class="contant card-one-content">
								<div class="name">
									<h6>{formData.name}</h6>
								</div>
								<div class="profession fi-size">{formData.profession}</div>
								</div>
								<div class="card-content">
									<div class="d-flex contact">
										<div class="fi-size"><i
												class="fa-solid fa-envelope icon"></i>{formData.email}
										</div>
									</div>
									<div class="d-flex contact">
										<div class="fi-size"><i
												class="fa-solid fa-phone icon"></i>{formData.phone}
										</div>
									</div>
									<div class="d-flex contact">
										<div class="fi-size"><i
												class="fa-solid fa-location-dot icon"></i>{formData.location}
										</div>
									</div>
									<div class="d-flex contact">
										<div class="fi-size"><i
												class="fa-solid fa-globe icon"></i>{formData.website}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
		
        );
};

export default CardNo1;


