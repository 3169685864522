import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './cardinfo.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

//import './App.css';
import StepForm from './components/StepForm';


function App() {
  return (
    <div className="App">
      <header className="App-header">
       <StepForm />
      </header>
    </div>
  );
}

export default App;
