import React from 'react';
import logo from '../images/ci-logo-footer-1.png';


const Header = () => {
    return (
    <header>
        <nav class="navbar navbar-expand-lg navbar-light" id="navbar_menu">
            <div class="container">
            <a class="navbar-brand" href="#"><img src={logo} alt="" /></a>

                <div class="navbar-toggler b-btn collapsed" role="navigation" data-bs-toggle="collapse"
                    data-bs-target="#mainmenu" aria-expanded="false" aria-label="Toggle navigation">
                    <div class="hamburger">
                        <span class="bar"></span>
                        <span class="bar"></span>
                        <span class="bar"></span>
                    </div>
                </div>
                <div class="collapse navbar-collapse" id="mainmenu">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0 ">
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#about-section">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-current="page" href="#contact-section">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    );
};

export default Header;


