import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import html2pdf from 'html2pdf.js';
import PrintCardNo1 from './PrintCardNo1';
import PrintCardNo2 from './PrintCardNo2';
import PrintCardNo345 from './PrintCardNo345';
import Header from './Header';
import Footer from './Footer';

function Step4({ formData, selectedImage, onPrevious, selectedSize}) {
	
	const { number } = formData;
	
	const componentRef = useRef();

	const handleBack = () => {
		onPrevious();
	};

	const generatePDF = (event) => {
		event.preventDefault();
		const element = document.getElementById('ci-card');
		
		var opt = {
			filename: 'your-pdf-filename.pdf',
			margin: [20, 5, 40, 5],
		};
		
		if(selectedSize === "cardNo1" || selectedSize === "cardNo2"){
		var opt = {
			filename: 'your-pdf-filename.pdf',
			margin: [0, 5, 20, 5],
			pagebreak: { mode: ['avoid-all'] },
			};
			
		}
		html2pdf().set(opt).from(element).save();
	}
	
	
	
	
	const handlePrint = () => {

	const contentToPrint = document.getElementById('ci-card');
		
	const printWindow = window.open('', '_blank');
	
		printWindow.document.write(contentToPrint.innerHTML);
		printWindow.document.close();
		printWindow.print();
	};
	
	const quantity = formData.number;
	
  return (
	<>
		<div class="main">

			<Header />		

				<div class="main-card">
					<div class="container padding-custom">
						<div class="card-size">
							<div class="border-top"></div>
							<div class="step">
								<div class="card-number">
									<ul class="ci-input-card">
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">1</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Card Information</span></div>
											</div>
										</li>
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">2</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Choose Design</span></div>
											</div>
										</li>
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">3</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Review Card Info</span></div>
											</div>
										</li>
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">4</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Print Preview</span></div>
											</div>
										</li>	
									</ul>
									<div class="clear"></div>
								</div>
							</div>
							<div class="col-lg-10 offset-lg-1 ml-auto">
								<div class="form-layout">
									<div class="row">
										<div class="card-print">
											<div class="download-print">
												<div class="row">
													<div class="col-lg-3 col-md-3 col-sm-12">
														<div class="download-btn">
															<button onClick={generatePDF} class="ci-btn"><i class="fa-solid fa-download icon"></i>Download</button>
														</div>
													</div>
													<div class="col-3"></div>
													<div class="col-3"></div>
													<div class="col-lg-3 col-md-3 col-sm-12">
														<div class="print-btn">
															<ReactToPrint
																trigger={() => <button class="ci-btn"><i class="fa-solid fa-print icon"></i>Print</button>}
																content={() => componentRef.current}
															/>
														</div>
													</div>
												</div>
											</div>
											{selectedSize === "cardNo1" ? ( 
													<PrintCardNo1 quantity={quantity} formData={formData}  selectedImage={selectedImage} selectedSize={selectedSize} ref={componentRef}/>
												 ): selectedSize === "cardNo2" ? (
                                                    <PrintCardNo2 quantity={quantity} formData={formData}  selectedImage={selectedImage} selectedSize={selectedSize} ref={componentRef}/>
                                                 ) : (
                                                    <PrintCardNo345 quantity={quantity} formData={formData}  selectedImage={selectedImage} selectedSize={selectedSize} ref={componentRef}/>
                                                 
                                                 )}
										</div>
										<div class="back-next">
												<div class="row">
													<div class="col-lg-3 col-md-3 col-sm-12">
														<button onClick={handleBack} class="ci-btn">Preview</button>
													</div>
												</div>
											</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>





			<Footer />

		</div>

	</>
  );
}

export default Step4;