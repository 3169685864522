import React from 'react';
import QRCode from 'qrcode.react';

const PrintCardNo345 = React.forwardRef((props, ref) => {
    const { selectedImage, formData, selectedSize, quantity } = props;
    
    	const generateVCard = () => {
		const { name, profession, email, phone, location} = formData;
			return `BEGIN:VCARD
			VERSION:3.0
			FN:${name}
			TEL:${phone}
			EMAIL:${email}
			END:VCARD`;
	};
	  
	const qrData = generateVCard();
	
    return (
	<div id="ci-card" ref={ref}>
		<div className="print-card print-mode vertical-print">
			<div class="print-vertical-card-bg ci-vertical-card">
				<div class="print-vertical-card-content">
					<div class="container">
					{Array.from({ length: Math.ceil(quantity / 6) }, (_, parentIndex) => ( 
						<div class="row ci-margin-top">
						{Array.from({ length: Math.min(quantity - parentIndex * 6, 6) }, (_, childIndex) => (
							<div class="col-lg-4 col-md-6 col-sm-12 p-0 m-0 desh-border" >
								<div class="Portrait-bg ci-card-bg-five portrait-mode" style={{ backgroundImage: `url(${selectedImage})` }}>
									<div class="portrait-card">
										<div class="container portrait-center">
											<div class="portrait-card-content white-txt">
												<div class="name">
													<h6>{formData.name}</h6>
												</div>
												<div class="profession fi-size">{formData.profession}
                                                <div class="vertical-qr"><QRCode value={qrData} size={80}/></div>
												</div>
											</div>
											<div class="ci-portrait-card-content">
												<div class="d-flex contact">
													<div class="fi-size"><i
															class="fa-solid fa-envelope icon"></i>{formData.email}
													</div>
												</div>
												<div class="d-flex contact">
													<div class="fi-size"><i
															class="fa-solid fa-phone icon"></i>{formData.phone}
													</div>
												</div>
												<div class="d-flex contact">
													<div class="fi-size"><i
															class="fa-solid fa-location-dot icon"></i>{formData.location}
													</div>
												</div>
												<div class="d-flex contact">
													<div class="fi-size"><i
															class="fa-solid fa-globe icon"></i>{formData.website}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							))}
							<div className="break-after"></div>
						</div>
						
					))}
					</div>
				</div>
			</div>
		</div>

	</div> 
    );
});

export default PrintCardNo345;


