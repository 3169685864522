import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import card1 from '../images/card-d.png';
import card2 from '../images/img-8.png';
import card3 from '../images/img-1.png';
import card4 from '../images/img-5.png';
import card5 from '../images/card-4.png';
import qr from '../images/qr-code.png';
import Header from './Header';
import Footer from './Footer';

function Step2({ onNext, formData, onPrevious}) {
	var cardNo1 = "cardNo1";
	var cardNo2 = "cardNo2";
	var cardNo3 = "cardNo3";
	var cardNo4 = "cardNo4";
	var cardNo5 = "cardNo5";
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedSize, setSelectedSize] = useState('');

  const handleNext = (event) => {
	  console.log(selectedSize);
	if (selectedImage == '') {
		alert("please select image");
		event.preventDefault();
	}  else {
		onNext(selectedImage, selectedSize);
	}
  };

  const handleBack = () => {
    onPrevious();
  };  
	const handleDivClick = (imagePath, size) => {
		setSelectedImage(imagePath);
		setSelectedSize(size);
	};

	const generateVCard = () => {
		const { name, profession, email, phone, location} = formData;
			return `BEGIN:VCARD
			VERSION:3.0
			FN:${name}
			TEL:${phone}
			EMAIL:${email}
			END:VCARD`;
	};
	  
	const qrData = generateVCard();

  return (
	<>
		<div class="main">

			<Header />		
				
				<div class="main-card">
					<div class="container">
						<div class="card-size">
							<div class="border-top"></div>
							<div class="step">
								<div class="card-number">
									<ul class="ci-input-card">
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">1</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Card Information</span></div>
											</div>
										</li>
										<li class="ci-in-crd current visited last">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text active">2</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Choose Design</span></div>
											</div>
										</li>
										<li class="ci-in-crd">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text three">3</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Review Card Info</span></div>
											</div>
										</li>
										<li class="ci-in-crd">
											<div class="ci-in-crd-inner">
												<div class="ci-tab-number">
													<span class="number-text four">4</span>
													<span class="tab-completed-icon"></span>
												</div>
												<div class="ci-tab-text"><span>Print Preview</span></div>
											</div>
										</li>
									</ul>
								</div>
							</div>


							<div class="col-lg-10 offset-lg-1 ml-auto">
								<div class="form-layout">

									<div class="landscape-card-design">

										<div class="ci-layout-title">
											<h3>Landscape Card Layout</h3>
										</div>

										<div class="row">
											<div class="col-lg-6 col-md-12 col-sm-12 p-0 m-0">
												<div class="landscape-bg ci-card-bg-one" style={{ backgroundImage: `url(${card2})` }} onClick={() => handleDivClick(card2, cardNo1)}>
													<div class="landscape-card-content">
														<div class="container content-center">
															<div class="row">
																<div class="col-lg-4 col-md-4 col-sm-4 col-4">
																<div class="qrone"><QRCode value={qrData} size={80}/></div>
																</div>
																<div class="col-lg-8 col-md-8 col-sm-8 col-8">
																<div class="contant card-one-content">
																	<div class="name">
																		<h6>{formData.name}</h6>
																	</div>
																	<div class="profession fi-size">{formData.profession}</div>
																	</div>
																	<div class="card-content">
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-envelope icon"></i>{formData.email}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-phone icon"></i>{formData.phone}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-location-dot icon"></i>{formData.location}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-globe icon"></i>{formData.website}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-6 col-md-12 col-sm-12 p-0 m-0">
												<div class="landscape-bg ci-card-bg-two" style={{ backgroundImage: `url(${card1})` }} onClick={() => handleDivClick(card1, cardNo2)}>
													<div class="landscape-card-content">
														<div class="container content-center">
															<div class="row">
																<div class="col-lg-8 col-md-8 col-sm-8 col-8">
																	<div class="contant card-two-content">
																		<div class="name">
																			<h6>{formData.name}</h6>
																		</div>
																		<div class="profession fi-size">{formData.profession}</div>
																	</div>
																	<div class="card-content peronal-d">
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-envelope icon"></i>{formData.email}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-phone icon"></i>{formData.phone}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-location-dot icon"></i>{formData.location}
																			</div>
																		</div>
																		<div class="d-flex contact">
																			<div class="fi-size"><i
																					class="fa-solid fa-globe icon"></i>{formData.website}
																			</div>
																		</div>
																	</div>
																</div>
																<div class="col-lg-4 col-md-4 col-sm-4 col-4">
																	<div class="qr"><QRCode value={qrData} size={80} /></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="portrait-card-design">
										<div class="ci-layout-title">
											<h3>Portrait Card Layout</h3>
										</div>

										<div class="row">
											<div class="col-lg-4 col-md-12 col-sm-12 p-0 m-0">
												<div class="Portrait-bg ci-card-bg-three" style={{ backgroundImage: `url(${card3})` }} onClick={() => handleDivClick(card3, cardNo3)}>
													<div class="portrait-card">
														<div class="container portrait-center">
															<div class="portrait-card-content black-txt">
																<div class="name">
																	<h6>{formData.name}</h6>
																</div>
																<div class="profession fi-size">{formData.profession}</div>
																<div class="vertical-qr"><QRCode value={qrData} size={80} /></div>
															</div>
															<div class="ci-portrait-card-content">
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-envelope icon"></i>{formData.email}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-phone icon"></i>{formData.phone}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-location-dot icon"></i>{formData.location}
																	</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-globe icon"></i>{formData.website}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-4 col-md-12 col-sm-12 p-0 m-0">
												<div class="Portrait-bg ci-card-bg-for" style={{ backgroundImage: `url(${card4})` }} onClick={() => handleDivClick(card4, cardNo4)}>
													<div class="portrait-card">
														<div class="container portrait-center">
															<div class="portrait-card-content black-txt">
																<div class="name">
																	<h6>{formData.name}</h6>
																</div>
																<div class="profession fi-size">{formData.profession}</div>
																<div class="vertical-qr"><QRCode value={qrData} size={80} /></div>
															</div>
															<div class="ci-portrait-card-content">
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-envelope icon"></i>{formData.email}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-phone icon"></i>{formData.phone}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-location-dot icon"></i>{formData.location}
																	</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-globe icon"></i>{formData.website}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="col-lg-4 col-md-12 col-sm-12 p-0 m-0">
												<div class="Portrait-bg ci-card-bg-five" style={{ backgroundImage: `url(${card5})` }} onClick={() => handleDivClick(card5, cardNo5)}>
													<div class="portrait-card">
														<div class="container portrait-center">
															<div class="portrait-card-content black-txt">
																<div class="name">
																	<h6>{formData.name}</h6>
																</div>
																<div class="profession fi-size">{formData.profession}</div>
																<div class="vertical-qr"><QRCode value={qrData} size={80} /></div>
															</div>
															<div class="ci-portrait-card-content">
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-envelope icon"></i>{formData.email}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-phone icon"></i>{formData.phone}</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-location-dot icon"></i>{formData.location}
																	</div>
																</div>
																<div class="d-flex contact">
																	<div class="fi-size"><i
																			class="fa-solid fa-globe icon"></i>{formData.website}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="back-next">
										<div class="row">
											<div class="col-lg-3 col-md-3 col-sm-12">
												<button onClick={handleBack} class="ci-btn">Back</button>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-12"></div>
											<div class="col-lg-3 col-md-3 col-sm-12"></div>
											<div class="col-lg-3 col-md-3 col-sm-12">
												<button onClick={handleNext} class="ci-btn">Next</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			<Footer />	

		</div>
	</>
  );
}

export default Step2;