import React, { useState } from 'react';
import CardNo1 from './CardNo1';
import CardNo2 from './CardNo2';
import CardNo3 from './CardNo3';
import CardNo4 from './CardNo4';
import CardNo5 from './CardNo5';
import Header from './Header';
import Footer from './Footer';

function Step3({ onNext, selectedImage, onPrevious, formData, selectedSize}) {

	const [number, setNumber] = useState('');
	
	const handleNumberChange = (event) => {
		setNumber(event.target.value);
    };
	
	const handleNext = (event) => {
		event.preventDefault();
		// Convert the entered value to a number
		const parsedNumber = parseFloat(number);
		console.log(parsedNumber);
		// Check if the parsedNumber is a valid number
		if (!isNaN(parsedNumber)) {
		  onNext({ number: parsedNumber });
		  
		}
    };
	
	  const handleBack = () => {
		onPrevious();
	  };

  return (
	<>
		<div class="main">

			<Header />	

			<div class="main-card">
				<div class="container">
					<div class="card-size">
						<div class="border-top"></div>
						<div class="step">
							<div class="card-number">
								<ul class="ci-input-card">
									<li class="ci-in-crd current visited last">
										<div class="ci-in-crd-inner">
											<div class="ci-tab-number">
												<span class="number-text active">1</span>
												<span class="tab-completed-icon"></span>
											</div>
											<div class="ci-tab-text"><span>Card Information</span></div>
										</div>
									</li>
									<li class="ci-in-crd current visited last">
										<div class="ci-in-crd-inner">
											<div class="ci-tab-number">
												<span class="number-text active">2</span>
												<span class="tab-completed-icon"></span>
											</div>
											<div class="ci-tab-text"><span>Choose Design</span></div>
										</div>
									</li>
									<li class="ci-in-crd current visited last">
										<div class="ci-in-crd-inner">
											<div class="ci-tab-number">
												<span class="number-text active">3</span>
												<span class="tab-completed-icon"></span>
											</div>
											<div class="ci-tab-text"><span>Review Card Info</span></div>
										</div>
									</li>
									<li class="ci-in-crd">
										<div class="ci-in-crd-inner">
											<div class="ci-tab-number">
												<span class="number-text four">4</span>
												<span class="tab-completed-icon"></span>
											</div>
											<div class="ci-tab-text"><span>Print Preview</span></div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-lg-10 offset-lg-1 ml-auto">
							<div class="form-layout">
								<div class="row">
									<div class="mx-auto col-12 col-md-12 col-lg-12">
										<h3>Review the card information before printing</h3>
										<div class="row">
											<div class="col-lg-6 col-md-12 col-sm-12 offset-lg-3 auto">
											{selectedSize === "cardNo2" ? (
												<CardNo2 formData={formData} selectedImage={selectedImage} selectedSize={selectedSize} />
												
											) : selectedSize === "cardNo3" ? (
												<CardNo3 formData={formData} selectedImage={selectedImage} selectedSize={selectedSize} />
											) : selectedSize === "cardNo4" ? (
												<CardNo4 formData={formData} selectedImage={selectedImage} selectedSize={selectedSize} />
											) : selectedSize === "cardNo5" ? (
												<CardNo5 formData={formData} selectedImage={selectedImage} selectedSize={selectedSize} />
											) : (
												<CardNo1 formData={formData} selectedImage={selectedImage} selectedSize={selectedSize} />
											)}
											<div class="quantity-form">
													<input placeholder="Enter Quntity" type="number" id="quantity" name="quantity" min="0"
														max="United" value={number} onChange={handleNumberChange} />
											</div>
											</div>
										</div>
										<div class="back-next">
											<div class="row">
												<div class="col-lg-3 col-md-3 col-sm-12">
													<button onClick={handleBack} class="ci-btn">Back</button>
												</div>
												<div class="col-lg-3 col-md-3 col-sm-12"></div>
												<div class="col-lg-3 col-md-3 col-sm-12"></div>
												<div class="col-lg-3 col-md-3 col-sm-12">
													<button onClick={handleNext} class="ci-btn">Next</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<Footer />

		</div>

	</>
  );
}

export default Step3;