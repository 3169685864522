import React from 'react';
import QRCode from 'qrcode.react';

const PrintCardNo1 = React.forwardRef((props, ref) => {
    const { selectedImage, formData, selectedSize, quantity } = props;

	const generateVCard = () => {
		const { name, profession, email, phone, location} = formData;
			return `BEGIN:VCARD
			VERSION:3.0
			FN:${name}
			TEL:${phone}
			EMAIL:${email}
			END:VCARD`;
	};
	  
	const qrData = generateVCard();
	
    return (
		<div id="ci-card" ref={ref}>
			
			{Array.from({ length: Math.ceil(quantity / 8) }, (_, parentIndex) => (
			<>	
				<ul className={`print-card horizontal c-horizontal-card  ${selectedSize}`}>
					{Array.from({ length: Math.min(quantity - parentIndex * 8, 8) }, (_, childIndex) => (
					
					<li class="print-card-bg mx-0 horizontal-bg-image" >
					<div className={`landscape-bg horizontal-card customone ci-mg ${selectedSize}`} style={{ backgroundImage: `url(${selectedImage})` }}>
						<div class="landscape-card-content">
							<div class="container content-center">
								<div class="row">
									<div class="col-lg-4 col-md-4 col-sm-4 col-4">
									<div class="qrone"><QRCode value={qrData} size={80}/></div>
									</div>
									<div class="col-lg-8 col-md-8 col-sm-8 col-8">
									<div class="contant card-one-content">
										<div class="name">
											<h6>{formData.name}</h6>
										</div>
										<div class="profession fi-size">{formData.profession}</div>
										</div>
										<div class="card-content">
											<div class="d-flex contact">
												<div class="fi-size"><i
														class="fa-solid fa-envelope icon"></i>{formData.email}
												</div>
											</div>
											<div class="d-flex contact">
												<div class="fi-size"><i
														class="fa-solid fa-phone icon"></i>{formData.phone}
												</div>
											</div>
											<div class="d-flex contact">
												<div class="fi-size"><i
														class="fa-solid fa-location-dot icon"></i>{formData.location}
												</div>
											</div>
											<div class="d-flex contact">
												<div class="fi-size"><i
														class="fa-solid fa-globe icon"></i>{formData.website}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					</li>
					))}
			</ul>
			<div className="break-after"></div>
			<div class="clear"></div>
			</>				
			))}
		</div> 
    );
});

export default PrintCardNo1;


