import React, { useState } from 'react';

import Header from './Header';
import Footer from './Footer';


function Step1({ onNext, formData }) {
  const [name, setName] = useState(formData.name || 'Your Name');
  const [profession, setProfession] = useState(formData.profession || 'Web Developer');
  const [email, setEmail] = useState(formData.email || 'abc@example.com');
  const [phone, setPhone] = useState(formData.phone || '98798740407');
  const [website, setWebsite] = useState(formData.website || 'www.example.com');
  const [company, setCompany] = useState(formData.company || 'Company Name');
  const [location, setLocation] = useState(formData.location || 'NY, USA');

  const handleNext = () => {
    onNext({ name, profession, email, phone, website, company, location });
  };

  return (
	
	<div class="main">

	<Header />	
		
		
		<div class="main-card">
            <div class="container">
                <div class="card-size">
                    <div class="border-top"></div>
                    <div class="step-one">
                        <div class="card-number">
                            <ul class="ci-input-card">
                                <li class="ci-in-crd current visited last">
                                    <div class="ci-in-crd-inner">
                                        <div class="ci-tab-number">
                                            <span class="number-text active">1</span>
                                            <span class="tab-completed-icon"></span>
                                        </div>
                                        <div class="ci-tab-text"><span>Card Information</span></div>
                                    </div>
                                </li>
                                <li class="ci-in-crd ">
                                    <div class="ci-in-crd-inner">
                                        <div class="ci-tab-number">
                                            <span class="number-text two">2</span>
                                            <span class="tab-completed-icon"></span>
                                        </div>
                                        <div class="ci-tab-text"><span>Choose Design</span></div>
                                    </div>
                                </li>
                                <li class="ci-in-crd">
                                    <div class="ci-in-crd-inner">
                                        <div class="ci-tab-number">
                                            <span class="number-text three">3</span>
                                            <span class="tab-completed-icon"></span>
                                        </div>
                                        <div class="ci-tab-text"><span>Review Card Info</span></div>
                                    </div>
                                </li>
                                <li class="ci-in-crd">
                                    <div class="ci-in-crd-inner">
                                        <div class="ci-tab-number">
                                            <span class="number-text four">4</span>
                                            <span class="tab-completed-icon"></span>
                                        </div>
                                        <div class="ci-tab-text"><span>Print Preview</span></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div class="col-lg-10 offset-lg-1 ml-auto">
                        <div class="form-layout">
                            <form>

								<div class="row g-3">
									<div class="col-md-6">
										<label class="form-label">Name</label>
										<input type="text" class="form-control" value={name} onChange={(e) => setName(e.target.value)} />
									</div>
									<div class="col-md-6">
										<label class="form-label">Profession</label>
										<input type="text" class="form-control" value={profession} onChange={(e) => setProfession(e.target.value)} />
									</div>
									<div class="col-md-6">
										<label class="form-label">Email</label>
										<input type="email" class="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
									</div>
									<div class="col-md-6">
										<label for="inputPassword4" class="form-label">Phone</label>
										<input type="phone" class="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} />
									</div>
									<div class="col-md-6">
										<label for="inputEmail4" class="form-label">Website</label>
										<input type="text" class="form-control" value={website} onChange={(e) => setWebsite(e.target.value)} />
									</div>
									<div class="col-md-6">
										<label for="inputPassword4" class="form-label">Company</label>
										<input type="text" class="form-control" value={company} onChange={(e) => setCompany(e.target.value)} />
									</div>
									<div class="col-12">
										<label for="inputAddress" class="form-label">Location</label>
										<input type="text" class="form-control" value={location} onChange={(e) => setLocation(e.target.value)} />
									</div>
									<div class="back-next">
										<div class="row">
											<div class="col-lg-9 col-md-9 col-sm-12">
												<div class="form-check">
													<input class="form-check-input" type="checkbox" id="gridCheck" />
													<label class="form-check-label" for="gridCheck">
														Check this if want to import data from CSV or Excel
														file.
													</label>
												</div>
											</div>
											<div class="col-lg-3 col-md-3 col-sm-12">
												<button class="btn ci-btn" onClick={handleNext}>Next</button>
											</div>
										</div>
									</div>
								</div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		
		
	<Footer />	


	</div>
		
  );
}

export default Step1;